import { Button, ReturnButton, Select } from '@leadsquare/design-system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameSimulator, SubtitleQuestion, TitleQuestion } from '../../../components';
import { setIsSliderNextButtonDisabled, setProfessionalActivity } from '../../../data/slices';
import { RootState } from '../../../data/store';
import { mappingProfessionalActivity, PagesName, ProfessionalActivity } from '../../../data/types';
import { useRedirectPreviousPage, useSendData } from '../../../hooks';
import { useRedirectIfNeeded } from '../../../hooks/useRedirectIfNeeded';

export const PageActiviteProfession = () => {
  const actualPage = PagesName.ACTIVITE_PROFESSION;
  const valueProfessionalActivity = useSelector((state: RootState) => state.userData.activity.professionalActivity);
  const dispatch = useDispatch();
  const sendData = useSendData();
  const redirectIfNeeded = useRedirectIfNeeded();
  const redirectPreviousPage = useRedirectPreviousPage();
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    if (valueProfessionalActivity) dispatch(setIsSliderNextButtonDisabled(false));
    else dispatch(setIsSliderNextButtonDisabled(true));
  }, [valueProfessionalActivity]);

  return (
    <div className="page page__activite page__activite__profession">
      <NameSimulator />
      <ReturnButton className="desktop" onClick={redirectPreviousPage}>
        Retour
      </ReturnButton>
      <TitleQuestion>Quelle est votre activité ?</TitleQuestion>
      <SubtitleQuestion>Pour identifier les meilleurs dispositifs</SubtitleQuestion>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendData();
        }}
        className="page__form-slider"
      >
        <Select
          onChange={(value) => {
            dispatch(setIsSliderNextButtonDisabled(false));
            dispatch(setProfessionalActivity(value as ProfessionalActivity));
            setTimeout(() => {
              buttonRef.current?.click();
            }, 500);
          }}
          value={valueProfessionalActivity as string}
          options={mappingProfessionalActivity}
        />
        <hr className="separator-submit" />
        <Button
          className="form-slider-submit"
          color="black"
          isArrow={true}
          disabled={valueProfessionalActivity === undefined}
          innerRef={buttonRef}
        >
          Suivant
        </Button>
      </form>
    </div>
  );
};
