import { Button, ReturnButton, Select } from '@leadsquare/design-system';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameSimulator, SubtitleQuestion, TitleQuestion } from '../../../components';
import { setFamilyNumChildren, setIsSliderNextButtonDisabled } from '../../../data/slices';
import { RootState } from '../../../data/store';
import { PagesName } from '../../../data/types';
import { useRedirectPreviousPage, useSendData } from '../../../hooks';
import { useRedirectIfNeeded } from '../../../hooks/useRedirectIfNeeded';

export const PageFamilleEnfants = () => {
  const actualPage = PagesName.FAMILLE_ENFANTS;
  const valueFamilyNumChildren = useSelector((state: RootState) => state.userData.family.numChildren);
  const dispatch = useDispatch();
  const sendData = useSendData();
  const redirectPreviousPage = useRedirectPreviousPage();
  const redirectIfNeeded = useRedirectIfNeeded();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    if (valueFamilyNumChildren !== undefined) dispatch(setIsSliderNextButtonDisabled(false));
    else dispatch(setIsSliderNextButtonDisabled(true));
  }, [valueFamilyNumChildren]);

  return (
    <div className="page page__famille page__famille__enfants">
      <NameSimulator />
      <ReturnButton className="desktop" onClick={redirectPreviousPage}>
        Retour
      </ReturnButton>
      <TitleQuestion>Combien d&apos;enfants avez vous à charge ?</TitleQuestion>
      <SubtitleQuestion>Pour affiner le montant d&apos;impôts</SubtitleQuestion>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendData();
        }}
        className="page__form-slider"
      >
        <Select
          onChange={(value) => {
            dispatch(setIsSliderNextButtonDisabled(false));
            dispatch(setFamilyNumChildren(Number(value)));
            setTimeout(() => {
              buttonRef.current?.click();
            }, 500);
          }}
          value={valueFamilyNumChildren?.toString() ?? ''}
          options={[
            { value: '0', label: "Je n'ai pas d'enfants" },
            { value: '1', label: '1 enfant' },
            { value: '2', label: '2 enfants' },
            { value: '3', label: '3 enfants' },
            { value: '4', label: '4 enfants ou plus' },
          ]}
        />
        <hr className="separator-submit" />
        <Button
          color="black"
          isArrow={true}
          disabled={valueFamilyNumChildren === undefined}
          className="form-slider-submit"
          innerRef={buttonRef}
        >
          Suivant
        </Button>
      </form>
    </div>
  );
};
