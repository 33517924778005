import { ApexOptions } from 'apexcharts';
import { putSpaces } from '../../utils';

export const chartOptions: ApexOptions = {
  grid: {
    show: true,
    strokeDashArray: 7,
    borderColor: '#23232033',
  },
  series: [
    {
      name: 'Versements cumulés',
      type: 'column',
      data: [],
    },
    {
      name: 'Intérêts cumulés',
      type: 'column',
      data: [],
    },
  ],
  chart: {
    height: 400,
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  responsive: [
    {
      breakpoint: 740,
      options: {
        legend: {
          position: 'bottom',
          offsetX: 0,
          offsetY: 27,
          itemMargin: {
            vertical: 5,
            horizontal: 10,
          },
        },
        title: {
          text: '',
        },
        yaxis: {
          tickAmount: 8,
          labels: {
            formatter: (value: number) => {
              return putSpaces(Math.round(value / 1000), 3) + 'k€';
            },
            style: {
              fontWeight: 500,
              colors: ['#040405'],
              fontSize: '10px',
              fontFamily: 'Maven Pro',
            },
          },
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
    },
  },
  xaxis: {
    type: 'numeric',
    categories: [],
    decimalsInFloat: 0,
    labels: {
      style: {
        fontFamily: 'Maven Pro',
        fontWeight: 500,
        fontSize: '14px',
        colors: ['#000000'],
      },
    },
  },
  yaxis: {
    tickAmount: 8,
    labels: {
      formatter: (value: number) => {
        return putSpaces(Math.round(value / 1000), 3) + 'k €';
      },
      style: {
        fontWeight: 500,
        colors: ['#040405'],
        fontSize: '14px',
        fontFamily: 'Maven Pro',
      },
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    offsetY: 0,
    markers: {
      radius: 10,
    },
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Maven Pro',
    itemMargin: {
      vertical: 24,
      horizontal: 10,
    },
  },
  title: {
    text: 'Calculs basés sur une performance annuelle de 5%',
    align: 'right',
    offsetY: 57,
    offsetX: -15,
    style: {
      fontSize: '14px',
      color: '#7D859B',
      fontWeight: '500 !important',
      fontFamily: 'Maven Pro',
    },
  },
  fill: {
    opacity: 1,
  },
  colors: ['#AB8CFD', '#170449'],
  dataLabels: {
    enabled: false,
  },
};
