import { HeaderPhoneSlider, LeftNavbar, NavSliderPhone } from '@leadsquare/design-system';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { NAME_SIMULATOR, NUM_REVIEWS, NUMBER_PAGES_SLIDER, PAGES_ORDER, CATEGORIES_STEPS } from '../../config';
import { RootState } from '../../data/store';
import { useRedirectPreviousPage, useSendData } from '../../hooks';
import './PageSlider.scss';

export const PageSlider = () => {
  const activeCategory = useSelector((state: RootState) => state.internalData.frontend.activeCategory);
  const isAnimated = useSelector((state: RootState) => state.internalData.frontend.progressBarAnimated);
  const isLastChecked = useSelector((state: RootState) => state.internalData.frontend.isLastCheckedProgressBar);
  const activePage = useSelector((state: RootState) => state.internalData.frontend.activePage);
  const isSliderNextButtonDisabled = useSelector(
    (state: RootState) => state.internalData.frontend.isSliderNextButtonDisabled,
  );
  const sendData = useSendData();
  const redirectPreviousPage = useRedirectPreviousPage();
  const [perCentProgress, setPerCentProgress] = React.useState(0);

  useEffect(() => {
    setPerCentProgress(
      Math.round(((PAGES_ORDER.indexOf(activePage ?? PAGES_ORDER[0]) + 1) / NUMBER_PAGES_SLIDER) * 100),
    );
  }, [activePage]);

  return (
    <div className="slider">
      <HeaderPhoneSlider nameSimulator={NAME_SIMULATOR} perCentProgress={perCentProgress} />
      <div className="slider__content">
        <LeftNavbar
          activeStep={activeCategory ?? 0}
          numReviews={436}
          steps={CATEGORIES_STEPS}
          animated={isAnimated}
          isLastChecked={isLastChecked}
        />
      </div>
      <div className="slider__page">
        <Outlet />
      </div>
      <NavSliderPhone
        numReviewsTrustPilot={NUM_REVIEWS}
        onReturn={redirectPreviousPage}
        onSubmit={() => {
          sendData();
        }}
        showReturnButton={activePage !== PAGES_ORDER[0]}
        isSubmitButtonDisabled={isSliderNextButtonDisabled}
      />
    </div>
  );
};
