import { configureStore } from '@reduxjs/toolkit';
import { internalDataSlice, userDataSlice } from './slices';

export const store = configureStore({
  reducer: {
    userData: userDataSlice.reducer,
    internalData: internalDataSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
