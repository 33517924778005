import React, { FC } from 'react';
import './TitleQuestion.scss';

interface TitleQuestionProps {
  children: string;
}

export const TitleQuestion: FC<TitleQuestionProps> = ({ children }) => {
  return <h2 className="title-question">{children}</h2>;
};
