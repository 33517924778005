import axios from 'axios';
import { useSelector } from 'react-redux';
import { BACKEND_URL } from '../config';
import { RootState } from '../data/store';

export const useSendPhoneVerification = () => {
  const uuidSimulation = useSelector((state: RootState) => state.internalData.backend.uuidSimulation);
  const phone = useSelector((state: RootState) => state.userData.information.phone);

  const sendPhoneVerification = async () => {
    if (!uuidSimulation || !phone) {
      console.error(
        '[useSendPhoneVerificationHook] Error, uuidSimulation or phone is null\nuuidSimulation:',
        uuidSimulation,
        'phone:',
        phone,
      );
      return false;
    }

    try {
      await axios.post(
        `${BACKEND_URL}/phone-verification/send-code/per/${uuidSimulation}`,
        {},
        {
          headers: {
            'uuid-simulation': uuidSimulation,
          },
        },
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          console.error(
            '[useSendPhoneVerificationHook] Error when trying to send a phone message (Bad request)\nResponse data:',
            error.response.data,
            'Request data:',
            { uuidSimulation, phone },
          );
          return false;
        }
        if (error.response?.status === 500) {
          console.error(
            '[useSendPhoneVerificationHook] Error when trying to send a phone message (Internal server error)\nResponse data:',
            error.response.data,
            'Request data:',
            { uuidSimulation, phone },
          );
          return false;
        }
        console.error(
          '[useSendPhoneVerificationHook] Error when trying to send a phone message\nResponse :',
          error,
          'Request data:',
          { uuidSimulation, phone },
        );
        return false;
      }
      console.error('[useSendPhoneVerificationHook] Unknown Error :', error);
      return false;
    }
    return true;
  };

  return sendPhoneVerification;
};
