import { Button, HeaderResultPage } from '@leadsquare/design-system';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../data/store';
import {
  FamilySituation,
  findLabelFromMapping,
  mappingFamilySituation,
  mappingProfessionalActivity,
  ProfessionalActivity,
} from '../data/types';
import { putSpacePhone, putSpaces } from '../utils';
import './PageAccount.scss';

export const PageAccount = () => {
  const navigate = useNavigate();
  const isPhoneChecked = useSelector((state: RootState) => state.internalData.backend.isPhoneChecked);
  const firstName = useSelector((state: RootState) => state.userData.information.firstName);
  const lastName = useSelector((state: RootState) => state.userData.information.lastName);
  const age = useSelector((state: RootState) => state.userData.family.age);
  const phone = useSelector((state: RootState) => state.userData.information.phone);
  const email = useSelector((state: RootState) => state.userData.information.email);
  const professionalActivity = useSelector((state: RootState) => state.userData.activity.professionalActivity);
  const monthlyIncome = useSelector((state: RootState) => state.userData.finances.income);
  const familySituation = useSelector((state: RootState) => state.userData.family.situation);
  const children = useSelector((state: RootState) => state.userData.family.numChildren);

  useEffect(() => {
    if (!isPhoneChecked) {
      navigate('/');
    }
  }, [isPhoneChecked, navigate]);

  return (
    <div className="page page__account">
      <HeaderResultPage
        onClick={() => {
          navigate('/account');
        }}
      />
      <div className="page__content">
        <h2 className="page__content__title">Mon profil</h2>
        <div className="page__content__table">
          <div className="page__content__table__column">
            <div className="page__content__table__row">
              <p className="table__label">Prénom</p>
              <p className="table__data">{firstName}</p>
            </div>
            <div className="page__content__table__row">
              <p className="table__label">Nom</p>
              <p className="table__data">{lastName}</p>
            </div>
            <div className="page__content__table__row">
              <p className="table__label">Âge</p>
              <p className="table__data">{age} ans</p>
            </div>
            <div className="page__content__table__row">
              <p className="table__label">Numéro</p>
              <p className="table__data">{putSpacePhone(phone ?? '')}</p>
            </div>
            <div className="page__content__table__row">
              <p className="table__label">E-mail</p>
              <p className="table__data">{email}</p>
            </div>
            <div className="page__content__table__row">
              <p className="table__label">Activité</p>
              <p className="table__data">
                {findLabelFromMapping(
                  mappingProfessionalActivity,
                  professionalActivity ?? ProfessionalActivity.SALARIE,
                )}
              </p>
            </div>
            <div className="page__content__table__row">
              <p className="table__label">Revenus mensuels</p>
              <p className="table__data">{putSpaces(monthlyIncome ?? 0, 3)} €/mois</p>
            </div>
          </div>
          <div className="page__content__table__column">
            <div className="page__content__table__row">
              <p className="table__label">Situation familiale</p>
              <p className="table__data">
                {findLabelFromMapping(mappingFamilySituation, familySituation ?? FamilySituation.CELIBATAIRE)}
              </p>
            </div>
            <div className="page__content__table__row">
              <p className="table__label">Enfants à charge</p>
              <p className="table__data">{children}</p>
            </div>
          </div>
        </div>

        <div className="page__content__button">
          <Button isArrow={true} color="black" onClick={() => navigate('/resultats')}>
            Retourner à mes résultats
          </Button>
        </div>
      </div>
    </div>
  );
};
