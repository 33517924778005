import { ModalNewSimulation } from '@leadsquare/design-system';
import React, { useEffect } from 'react';
import { useStartNewSimulation } from '../../hooks';
import { useCheckUuids, uuidFromLocalStorage } from '../../hooks/useCheckUuid';

export const ControlledModalNewSimulation = () => {
  const checkUuids = useCheckUuids();
  const [isModalNewSimulationOpen, setIsModalNewSimulationOpen] = React.useState(uuidFromLocalStorage() !== null);
  const startNewSimulation = useStartNewSimulation();

  useEffect(() => {
    checkUuids(undefined);
  }, []);

  const handleClickContinueSimulation = () => {
    setIsModalNewSimulationOpen(false);
  };

  const handleClickNewSimulation = async () => {
    await startNewSimulation(true);
    setIsModalNewSimulationOpen(false);
  };

  return (
    <ModalNewSimulation
      isOpen={isModalNewSimulationOpen}
      onClickContinueSimulation={handleClickContinueSimulation}
      onClickNewSimulation={handleClickNewSimulation}
    />
  );
};
