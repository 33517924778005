import { useDispatch } from 'react-redux';
import axios from 'axios';
import { BACKEND_URL, SIMULATION_ACRONYM } from '../config';
import { BackendResponseNewSimulationPer, PagesName } from '../data/types';
import { setActivePage, setInternalDataBackend, setMonthlyPayment, setUserData } from '../data/slices';

export const useStartNewSimulation = () => {
  const dispatch = useDispatch();
  const startNewSimulation = async (new_simulation: boolean, uuidSimulation?: string) => {
    if (!new_simulation && !uuidSimulation) {
      console.error('[useStartNewSimulationHook] Error, uuidSimulation is null but new simulation is true');
      return null;
    }

    const utm = localStorage.getItem('utm_bypass') ?? undefined;

    try {
      const response = await axios.post(
        `${BACKEND_URL}/start-simulation/per`,
        {
          new_simulation,
          uuid_simulation: !new_simulation ? uuidSimulation : undefined,
          utm: new_simulation ? utm : undefined,
        },
        {
          headers: {
            'uuid-simulation': uuidSimulation ?? '',
          },
        },
      );
      const data: BackendResponseNewSimulationPer = response.data;
      dispatch(setInternalDataBackend(data.internalData.backend));
      dispatch(setActivePage(data.internalData.frontend.activePage ?? PagesName.FAMILLE_SITUATION));
      dispatch(setMonthlyPayment(data.internalData.frontend.monthlyPayment));
      dispatch(setUserData(data.userData));
      localStorage.setItem(`uuid_simulation_${SIMULATION_ACRONYM}`, data.internalData.backend.uuidSimulation ?? '');
      return data.internalData.backend.uuidSimulation ?? null;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('[useStartNewSimulationHook] Error when trying to create a new simulation', error);
      } else {
        console.error('[useStartNewSimulationHook] Unknown Error :', error);
      }
    }
    return null;
  };

  return startNewSimulation;
};
