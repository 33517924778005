import React, { FC } from 'react';
import './SubtitleQuestion.scss';

interface SubtitleQuestionProps {
  children: string;
}

export const SubtitleQuestion: FC<SubtitleQuestionProps> = ({ children }) => {
  return <p className="body2 subtitle-question">{children}</p>;
};
