import { Button, Input, ReturnButton } from '@leadsquare/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameSimulator, SubtitleQuestion, TitleQuestion } from '../../../components';
import { setFirstName, setIsSliderNextButtonDisabled, setLastName } from '../../../data/slices';
import { RootState } from '../../../data/store';
import { PagesName } from '../../../data/types';
import { useRedirectPreviousPage, useSendData } from '../../../hooks';
import { useRedirectIfNeeded } from '../../../hooks/useRedirectIfNeeded';

export const PageInformationsNom = () => {
  const actualPage = PagesName.INFORMATIONS_NOM;
  const valueFirstName = useSelector((state: RootState) => state.userData.information.firstName);
  const valueLastName = useSelector((state: RootState) => state.userData.information.lastName);
  const dispatch = useDispatch();
  const sendData = useSendData();
  const redirectIfNeeded = useRedirectIfNeeded();
  const redirectPreviousPage = useRedirectPreviousPage();
  const [inputStateFirstName, setInputStateFirstName] = useState<'default' | 'success' | 'error'>('default');
  const [inputStateLastName, setInputStateLastName] = useState<'default' | 'success' | 'error'>('default');

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    if (inputStateFirstName === 'success' && inputStateLastName === 'success')
      dispatch(setIsSliderNextButtonDisabled(false));
    else dispatch(setIsSliderNextButtonDisabled(true));
  }, [inputStateFirstName, inputStateLastName, dispatch]);

  useEffect(() => {
    handleInputState(valueFirstName ?? '', setInputStateFirstName);
    handleInputState(valueLastName ?? '', setInputStateLastName);
  }, []);

  const handleInputState = (
    value: string,
    setInputState: React.Dispatch<React.SetStateAction<'default' | 'success' | 'error'>>,
  ) => {
    if (value.length === 0) setInputState('default');
    else if (value.length >= 2 && value.length <= 255) setInputState('success');
    else setInputState('error');
  };

  const handleChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    setInputState: React.Dispatch<React.SetStateAction<'default' | 'success' | 'error'>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setReducer: (value: any) => any,
  ) => {
    const value = event.target.value;
    handleInputState(value, setInputState);
    dispatch(setReducer(value));
  };

  return (
    <div className="page page__famille page__famille__age">
      <NameSimulator />
      <ReturnButton className="desktop" onClick={redirectPreviousPage}>
        Retour
      </ReturnButton>
      <TitleQuestion>Faisons plus ample connaissance.</TitleQuestion>
      <SubtitleQuestion>Nécéssaire pour accéder à nouveau à vos résultats.</SubtitleQuestion>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendData();
        }}
        className="page__form-slider"
      >
        <div className="slider__page__inputs-container-row">
          <Input
            state={inputStateFirstName}
            value={valueFirstName ?? ''}
            onChange={(event) => {
              handleChanges(event, setInputStateFirstName, setFirstName);
            }}
            type="text"
            placeholder="Ex: John"
            errorMessage="Veuillez entrer votre prénom"
            label="Prénom"
          />
          <Input
            state={inputStateLastName}
            value={valueLastName ?? ''}
            onChange={(event) => {
              handleChanges(event, setInputStateLastName, setLastName);
            }}
            type="text"
            placeholder="Ex: Doe"
            errorMessage="Veuillez entrer votre nom"
            label="Nom"
          />
        </div>
        <hr className="separator-submit" />
        <Button
          color="black"
          isArrow={true}
          disabled={inputStateFirstName !== 'success' || inputStateLastName !== 'success'}
          className="form-slider-submit"
        >
          Suivant
        </Button>
      </form>
    </div>
  );
};
