/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './data/store';
import {
  PageError404,
  PageActiviteProfession,
  PageCalculs,
  PageFamilleAge,
  PageFamilleEnfants,
  PageFamilleSituation,
  PageFinancesRevenus,
  PageInformationsEmail,
  PageInformationsNom,
  PageInformationsTelephone,
  PageResultats,
  PageSlider,
  PageAccount,
} from './pages';
import '@leadsquare/design-system/dist/index.css';
import '@leadsquare/design-system/src/styles/global.scss';
import { setUtmLocalStorage } from './utils';
import { ControlledModalNewSimulation } from './components';
import { ErrorBoundary, LEVEL_WARN, Provider as ProviderRollbar } from '@rollbar/react';
import { ROLLBAR_CONFIG } from './config';
import { Analytics } from '@vercel/analytics/react';

declare global {
  interface Window {
    dataLayer: any;
    gtag: any;
  }
}

const App = () => {
  useEffect(() => {
    setUtmLocalStorage();
  }, []);

  return (
    <div className="App">
      <ProviderRollbar config={ROLLBAR_CONFIG}>
        <ErrorBoundary level={LEVEL_WARN} errorMessage="Error in React render">
          <Provider store={store}>
            <ControlledModalNewSimulation />
            <Analytics />
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Routes>
                <Route path="*" element={<PageError404 />} />
                <Route path="/slider" element={<PageSlider />}>
                  <Route path="famille">
                    <Route path="situation" element={<PageFamilleSituation />} />
                    <Route path="age" element={<PageFamilleAge />} />
                    <Route path="enfants" element={<PageFamilleEnfants />} />
                  </Route>
                  <Route path="activite">
                    <Route path="profession" element={<PageActiviteProfession />} />
                  </Route>
                  <Route path="finances">
                    <Route path="revenus" element={<PageFinancesRevenus />} />
                  </Route>
                  <Route path="informations">
                    <Route path="nom" element={<PageInformationsNom />} />
                    <Route path="email" element={<PageInformationsEmail />} />
                    <Route path="telephone" element={<PageInformationsTelephone />} />
                  </Route>
                </Route>
                <Route path="calculs" element={<PageCalculs />} />
                <Route path="resultats" element={<PageResultats />} />
                <Route path="account" element={<PageAccount />} />
              </Routes>
            </BrowserRouter>
          </Provider>
        </ErrorBoundary>
      </ProviderRollbar>
    </div>
  );
};

export default App;
