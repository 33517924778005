import { Button, Select } from '@leadsquare/design-system';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameSimulator, SubtitleQuestion, TitleQuestion } from '../../../components';
import { setFamilySituation, setIsSliderNextButtonDisabled } from '../../../data/slices';
import { RootState } from '../../../data/store';
import { FamilySituation, mappingFamilySituation, PagesName } from '../../../data/types';
import { useSendData } from '../../../hooks';
import { useRedirectIfNeeded } from '../../../hooks/useRedirectIfNeeded';

export const PageFamilleSituation = () => {
  const actualPage = PagesName.FAMILLE_SITUATION;
  const valueFamilySituation = useSelector((state: RootState) => state.userData.family.situation);
  const dispatch = useDispatch();
  const sendData = useSendData();
  const redirectIfNeeded = useRedirectIfNeeded();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    if (valueFamilySituation) dispatch(setIsSliderNextButtonDisabled(false));
    else dispatch(setIsSliderNextButtonDisabled(true));
  }, [valueFamilySituation]);

  return (
    <div className="page page__famille page__famille__situation">
      <NameSimulator />
      <TitleQuestion>Quelle est votre situation ?</TitleQuestion>
      <SubtitleQuestion>Pour calculer le montant d&apos;impôts</SubtitleQuestion>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendData();
        }}
        className="page__form-slider"
      >
        <Select
          onChange={(value) => {
            dispatch(setIsSliderNextButtonDisabled(false));
            dispatch(setFamilySituation(value as FamilySituation));
            setTimeout(() => {
              buttonRef.current?.click();
            }, 500);
          }}
          value={valueFamilySituation as string}
          options={mappingFamilySituation}
        />
        <hr className="separator-submit" />
        <Button
          color="black"
          isArrow={true}
          disabled={valueFamilySituation === undefined}
          className="form-slider-submit"
          innerRef={buttonRef}
        >
          Suivant
        </Button>
      </form>
    </div>
  );
};
