import { ContentPageCalcul, HeaderTrustPilot } from '@leadsquare/design-system';
import React, { useEffect } from 'react';
import { NUM_REVIEWS } from '../config';
import { PagesName } from '../data/types';
import { useSendData, useSendPhoneVerification } from '../hooks';
import { useRedirectIfNeeded } from '../hooks/useRedirectIfNeeded';
import './PageCalculs.scss';

export const PageCalculs = () => {
  const actualPage = PagesName.CALCULS;
  const redirectIfNeeded = useRedirectIfNeeded();
  const sendData = useSendData();
  const sendPhoneVerification = useSendPhoneVerification();

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    sendPhoneVerification();
    const timer = setTimeout(() => {
      sendData();
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="page page-calculs">
      <HeaderTrustPilot numReviews={NUM_REVIEWS} />
      <div className="page-calculs__container">
        <ContentPageCalcul />
      </div>
      <footer></footer>
    </div>
  );
};
