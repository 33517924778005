import { useDispatch, useSelector } from 'react-redux';
import { BACKEND_URL, PAGES_ORDER } from '../config';
import { RootState } from '../data/store';
import { BackendData, PagesName, UserDataState } from '../data/types';
import axios, { AxiosError } from 'axios';
import { setActivePage, setIsSliderNextButtonDisabled } from '../data/slices';
import { useCheckUuids } from './useCheckUuid';
import { UuidError } from './errors';

const constructPayload = (page: PagesName, userData: UserDataState) => {
  const payload: BackendData = {
    activity: {
      professionalActivity: page === PagesName.ACTIVITE_PROFESSION ? userData.activity.professionalActivity : undefined,
    },
    family: {
      age: page === PagesName.FAMILLE_AGE ? userData.family.age : undefined,
      numChildren: page === PagesName.FAMILLE_ENFANTS ? userData.family.numChildren : undefined,
      situation: page === PagesName.FAMILLE_SITUATION ? userData.family.situation : undefined,
    },
    finances: {
      income: page === PagesName.FINANCES_REVENUS ? userData.finances.income : undefined,
    },
    information: {
      email: page === PagesName.INFORMATIONS_EMAIL ? userData.information.email : undefined,
      firstName: page === PagesName.INFORMATIONS_NOM ? userData.information.firstName : undefined,
      lastName: page === PagesName.INFORMATIONS_NOM ? userData.information.lastName : undefined,
      phone: page === PagesName.INFORMATIONS_TELEPHONE ? userData.information.phone : undefined,
    },
    other: {
      isCguAccepted: page === PagesName.INFORMATIONS_EMAIL ? userData.other.isCguAccepted : undefined,
      last_active_page:
        PAGES_ORDER[
          PAGES_ORDER.indexOf(page) + 1 !== PAGES_ORDER.length
            ? PAGES_ORDER.indexOf(page) + 1
            : PAGES_ORDER.indexOf(page)
        ],
    },
  };

  return payload;
};

export const useSendData = () => {
  const checkUuids = useCheckUuids();
  const activePage = useSelector((state: RootState) => state.internalData.frontend.activePage);
  const internalDataBackend = useSelector((state: RootState) => state.internalData.backend);
  const userData = useSelector((state: RootState) => state.userData);
  const dispatch = useDispatch();

  const sendData = async () => {
    try {
      const uuidSimulation = await checkUuids(internalDataBackend.uuidSimulation);

      if (!activePage) throw new Error('No active page found in the store.');

      const dataToSend: BackendData = {
        ...constructPayload(activePage, userData),
      };

      await axios.patch(`${BACKEND_URL}/update-simulation/per/${uuidSimulation}`, dataToSend, {
        headers: {
          'uuid-simulation': uuidSimulation,
        },
      });

      dispatch(setIsSliderNextButtonDisabled(true));
      dispatch(
        setActivePage(
          PAGES_ORDER[
            PAGES_ORDER.indexOf(activePage) + 1 !== PAGES_ORDER.length
              ? PAGES_ORDER.indexOf(activePage) + 1
              : PAGES_ORDER.indexOf(activePage)
          ],
        ),
      );
    } catch (error) {
      if (error instanceof UuidError) {
        console.error('[useSendDataHook]', error.message);
      } else if (axios.isAxiosError(error)) {
        const errorAxios = error as AxiosError;
        console.error(
          '[useSendDataHook] Error while sending data to the backend: \n - Status code: ',
          errorAxios.response?.status,
          '\n- Response data: ',
          errorAxios.response?.data,
        );
      } else {
        console.warn('[useUpdatePageHook] Error while sending data to the backend: ', error);
      }
    }
  };

  return sendData;
};
