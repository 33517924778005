import React, { ChangeEventHandler, Component } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { chartOptions } from './config';
import { getCapitalPer, putSpaces } from '../../utils';
import { INTEREST_RATE, RETIREMENT_AGE } from '../../config';
import './Chart.scss';
import { Input, Slider } from '@leadsquare/design-system';
import { debounce, DebouncedFunc } from 'lodash';

interface ChartProps {
  monthlyPayment: number;
  age: number;
  taxExemptionCeiling: number;
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  onSliderChange: (value: number[]) => void;
}

const roundValue = (value: number, min: number, max: number, step: number) => {
  const roundedValue = Math.round(value / step) * step;
  return Math.max(Math.min(roundedValue, max), min);
};

export class Chart extends Component<ChartProps> {
  options: ApexOptions;
  chart: ApexCharts | undefined;
  debouncedUpdatePlot: DebouncedFunc<() => void>;

  constructor(props: ChartProps) {
    super(props);
    this.options = chartOptions;
    this.debouncedUpdatePlot = debounce(this.updatePlot, 200);
  }

  componentDidMount() {
    const actualYear = new Date().getFullYear();
    const years = Array.from({ length: RETIREMENT_AGE - this.props.age }, (_, i) => actualYear + i);
    if (this.options.xaxis) this.options.xaxis.categories = years;
    this.chart = new ApexCharts(document.querySelector('.plot-result__plot'), this.options);
    this.chart.render();
    this.updatePlot();
  }

  componentDidUpdate(prevProps: ChartProps) {
    if (
      prevProps.age !== this.props.age ||
      prevProps.monthlyPayment !== this.props.monthlyPayment ||
      prevProps.taxExemptionCeiling !== this.props.taxExemptionCeiling
    ) {
      this.debouncedUpdatePlot();
    }
  }

  async updatePlot() {
    if (!this.chart) return;

    const data = getCapitalPer(this.props.monthlyPayment, this.props.age, INTEREST_RATE);

    await this.chart.updateSeries([
      {
        name: 'Versements cumulés',
        data: data.paymentEvolution,
        type: 'column',
      },
      {
        name: 'Intérêts cumulés',
        data: data.interestEvolution,
        type: 'column',
      },
    ]);
  }

  render() {
    return (
      <div className="plot-result">
        <h4 className="plot-title">Détails de votre simulation</h4>
        <div className="plot-result__plot"></div>
        <div className="plot-range">
          <Slider
            min={0}
            max={this.props.taxExemptionCeiling * 3 !== 0 ? this.props.taxExemptionCeiling * 3 : 1000}
            step={5}
            onChange={(event) => {
              this.props.onSliderChange(event);
            }}
            values={[roundValue(this.props.monthlyPayment, 0, this.props.taxExemptionCeiling * 3, 5)]}
          />
          <Input
            label="Vos versements"
            state="selected"
            inputMode="numeric"
            type="text"
            value={this.props.monthlyPayment !== 0 ? putSpaces(this.props.monthlyPayment, 3) : ''}
            onChange={(event) => {
              this.props.onInputChange(event);
            }}
            rightIcon={'€'}
          />
        </div>
      </div>
    );
  }
}
