import { PagesName } from './data/types';

export const NUM_REVIEWS = 426;
export const CATEGORIES_STEPS = ['Famille', 'Activité', 'Finances', 'Informations'];
export const NAME_SIMULATOR = 'Simulateur plan épargne retraite';
export const PAGES_ORDER = [
  PagesName.FAMILLE_SITUATION,
  PagesName.FAMILLE_AGE,
  PagesName.FAMILLE_ENFANTS,
  PagesName.ACTIVITE_PROFESSION,
  PagesName.FINANCES_REVENUS,
  PagesName.INFORMATIONS_NOM,
  PagesName.INFORMATIONS_EMAIL,
  PagesName.INFORMATIONS_TELEPHONE,
  PagesName.CALCULS,
  PagesName.RESULTATS,
];
export const NUMBER_PAGES_SLIDER = 8;
export const PAGES_ORDER_PER_CATEGORY_SLIDER = [
  [PagesName.FAMILLE_SITUATION, PagesName.FAMILLE_AGE, PagesName.FAMILLE_ENFANTS],
  [PagesName.ACTIVITE_PROFESSION],
  [PagesName.FINANCES_REVENUS],
  [PagesName.INFORMATIONS_NOM, PagesName.INFORMATIONS_EMAIL, PagesName.INFORMATIONS_TELEPHONE],
];
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const ROLLBAR_CONFIG = {
  accessToken: '4983983337304a7ab928fe60f0cfcae3',
  environment: 'development',
};
export const SIMULATION_ACRONYM = 'per';
export const RETIREMENT_AGE = 65;
export const INTEREST_RATE = 0.05;
export const MONTANT_PASS = 43992;
export const TAXES_0 = 10777;
export const TAXES_11 = 27478;
export const TAXES_30 = 78570;
export const TAXES_41 = 168994;
