import axios from 'axios';
import { useSelector } from 'react-redux';
import { BACKEND_URL } from '../config';
import { RootState } from '../data/store';
import { ProfessionalActivity, UserDataState } from '../data/types';
import { calculateValueGAds } from '../utils';

const sendGtagPhoneChecked = (userData: UserDataState) => {
  window.gtag('event', 'conversion', {
    send_to: 'AW-10875792133/-HO8COaW-q0DEIXW_cEo',
    value: calculateValueGAds(
      userData.finances.income ?? 0,
      userData.activity.professionalActivity ?? ProfessionalActivity.SALARIE,
      userData.family.age ?? 18,
    ),
    currency: 'EUR',
  });
};

export const useCheckPhoneVerification = () => {
  const uuidSimulation = useSelector((state: RootState) => state.internalData.backend.uuidSimulation);
  const phone = useSelector((state: RootState) => state.userData.information.phone);
  const userData = useSelector((state: RootState) => state.userData);

  const checkPhoneVerification = async (
    code: string,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    if (!uuidSimulation || !phone) {
      console.error(
        '[useCheckPhoneVerificationHook] Error, uuidSimulation or phone is null\nuuidSimulation:',
        uuidSimulation,
        'phone:',
        phone,
      );
      return false;
    }

    try {
      const response = await axios.post(
        `${BACKEND_URL}/phone-verification/check-code/per/${uuidSimulation}`,
        {
          code,
        },
        {
          headers: {
            'uuid-simulation': uuidSimulation,
          },
        },
      );
      if (response.status !== 201) {
        throw new Error('Response status is not 201');
      }

      if (response.data.status !== 'success') {
        setErrorMessage(response.data.message);
        return false;
      }

      sendGtagPhoneChecked(userData);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          '[useCheckPhoneVerificationHook] Error when trying to check a phone\nResponse :',
          error,
          'Request data:',
          { uuidSimulation, phone, code },
        );
        return false;
      }
      console.error('[useCheckPhoneVerificationHook] Unknown Error :', error);
      return false;
    }

    return false;
  };

  return checkPhoneVerification;
};
