import axios from 'axios';
import { useSelector } from 'react-redux';
import { BACKEND_URL } from '@/config';
import { RootState } from '@data';

export const useResendPhoneVerification = () => {
  const uuidSimulation = useSelector((state: RootState) => state.internalData.backend.uuidSimulation);

  const resendPhoneVerification = async (setErrorMessage: React.Dispatch<React.SetStateAction<string>>) => {
    if (!uuidSimulation) {
      console.error('[useResendPhoneVerificationHook] Error, uuidSimulation is null\nuuidSimulation:', uuidSimulation);
      return false;
    }

    try {
      const response = await axios.post(
        `${BACKEND_URL}/phone-verification/resend-code/per/${uuidSimulation}`,
        {},
        {
          headers: {
            'uuid-simulation': uuidSimulation,
          },
        },
      );

      if (response.status !== 201) {
        throw new Error('Response status is not 201');
      }
      setErrorMessage(response.data.message);

      if (response.data.status !== 'success') return false;
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          '[useResendPhoneVerificationHook] Error when trying to check a phone\nResponse :',
          error,
          'Request data:',
          { uuidSimulation },
        );
        return false;
      }
      console.error('[useResendPhoneVerificationHook] Unknown Error :', error);
      return false;
    }

    return false;
  };

  return resendPhoneVerification;
};
