import { INTEREST_RATE, MONTANT_PASS, RETIREMENT_AGE, TAXES_0, TAXES_11, TAXES_30, TAXES_41 } from '../config';
import { FamilySituation, ProfessionalActivity } from '../data/types';

export function putSpaces(number: string | number, n: number) {
  let numberString: string;
  if (typeof number == 'string') {
    number = number.replaceAll(' ', '');
    numberString = number;
  } else {
    if (isNaN(number)) return '0';
    numberString = number.toString();
  }

  let toReturn = '';
  for (let i = 0; i < numberString.length; i++) {
    if (i % n === 0 && i !== 0) {
      toReturn += ' ';
    }

    toReturn += numberString[numberString.length - 1 - i];
  }

  return toReturn.split('').reverse().join('');
}

export function keepDigits(value: string): string {
  return value.replace(/[^0-9]/g, '');
}

export function checkEmail(email: string): boolean {
  const regex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[-\x21\x23-\x5b\x5d-\x7f]|\\[-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[-\x21-\x5a\x53-\x7f]|\\[-\x7f])+)\])/;
  return regex.test(email);
}

export function regexParseInt(value: string | number) {
  if (typeof value === 'string') {
    return parseInt(value.replaceAll(/\D+/g, ''));
  }
  return value;
}

export function putSpacePhone(number: string | number) {
  //First Deleting probable spaces
  let numberString;
  if (typeof number == 'string') {
    const char0 = number[0];
    number = regexParseInt(number).toString();
    numberString = number;
    if (char0 === '0' && numberString !== '0') {
      numberString = '0' + numberString;
    }
  } else {
    numberString = number.toString();
  }
  if (!isNaN(regexParseInt(numberString))) {
    let toReturn = '';
    for (let i = 0; i < numberString.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        toReturn += ' ';
      }
      toReturn += numberString[i];
    }
    return toReturn;
  }
  return '';
}

export function getCapitalPer(
  monthlyPayment: number,
  age: number,
  interestRate: number,
): {
  capitalTerm: number;
  capitalEvolution: number[];
  paymentEvolution: number[];
  interestEvolution: number[];
} {
  /**
   * @param monthlyPayment: montant mensuel versé
   * @param age: âge actuel
   * @param interestRate: taux d'intérêt (entre 0 et 1)
   * @returns capitalTerm: capital à la fin de la période d'épargne
   * @returns capitalEvolution: tableau contenant le capital à chaque année
   * @returns paymentEvolution: tableau contenant le montant versé à chaque année
   * @returns interestEvolution: tableau contenant le montant des intérêts à chaque année
   */

  const annualPayment = monthlyPayment * 12;
  let capitalTerm = annualPayment * (1 + interestRate);
  const paymentEvolution = [Math.round(annualPayment)];
  const interestEvolution = [Math.round(paymentEvolution[0] * interestRate)];
  const capitalEvolution = [Math.round(paymentEvolution[0] + interestEvolution[0])];
  const periodSaving = RETIREMENT_AGE - age;

  for (let i = 1; i < periodSaving; i++) {
    interestEvolution.push(
      Math.round(interestEvolution[interestEvolution.length - 1] + (capitalTerm + annualPayment) * interestRate),
    );
    capitalTerm = Math.round((capitalTerm + annualPayment) * (1 + interestRate));
    paymentEvolution.push(Math.round(paymentEvolution[paymentEvolution.length - 1] + annualPayment));
    capitalEvolution.push(
      Math.round(paymentEvolution[paymentEvolution.length - 1] + interestEvolution[interestEvolution.length - 1]),
    );
  }

  return {
    capitalTerm: Math.round(capitalTerm),
    capitalEvolution: capitalEvolution,
    paymentEvolution: paymentEvolution,
    interestEvolution: interestEvolution,
  };
}

export function getTaxExemptionCeiling(activity: ProfessionalActivity | undefined, monthlyIncome: number | undefined) {
  if (!activity || !monthlyIncome) return Math.round((0.1 * MONTANT_PASS) / 12);
  const annualIncome = monthlyIncome * 12;

  if (activity === ProfessionalActivity.INDEPENDANT) {
    if (annualIncome < MONTANT_PASS) {
      return Math.round((0.1 * MONTANT_PASS) / 12);
    }
    return Math.round(
      Math.min(
        0.1 * annualIncome + 0.15 * (annualIncome - MONTANT_PASS),
        0.1 * MONTANT_PASS + 0.15 * 7 * MONTANT_PASS,
      ) / 12,
    );
  }
  if (annualIncome < MONTANT_PASS) {
    return Math.round((0.1 * MONTANT_PASS) / 12);
  }
  return Math.round((0.1 * Math.min(annualIncome, 8 * MONTANT_PASS)) / 12);
}

export function getCapitalPerOnly(monthlyPayment: number, age: number) {
  const annualPayment = monthlyPayment * 12;
  let capitalTerm = 0;
  const periodSaving = RETIREMENT_AGE - age;

  for (let i = periodSaving; i >= 1; i = i - 1) {
    capitalTerm = (capitalTerm + annualPayment) * (1 + INTEREST_RATE);
  }
  return Math.round(capitalTerm);
}

export function getNbPart(situation: FamilySituation, numChildren: number) {
  let numPart = 0;

  if (
    situation === FamilySituation.CELIBATAIRE ||
    situation === FamilySituation.DIVORCE ||
    situation === FamilySituation.CONCUBINAGE
  ) {
    numPart = 1;
    for (let i = 0; i < numChildren; i++) {
      if (i < 2) numPart += 0.5;
      else numPart += 1;
    }
    return numPart;
  } else if (situation === FamilySituation.MARIE_PACSE || situation === FamilySituation.VEUF) {
    numPart = 2;
    for (let i = 0; i < numChildren; i++) {
      if (i < 2) numPart += 0.5;
      else numPart += 1;
    }
    return numPart;
  }
  return 0;
}

export function getTmi(monthlyIncome: number, numPart: number) {
  const incomeAnnual = (Math.round(monthlyIncome) * 12) / numPart;

  if (incomeAnnual <= TAXES_0) {
    return 0;
  } else if (incomeAnnual > TAXES_0 && incomeAnnual <= TAXES_11) {
    return 11;
  } else if (incomeAnnual > TAXES_11 && incomeAnnual <= TAXES_30) {
    return 30;
  } else if (incomeAnnual >= TAXES_30 && incomeAnnual <= TAXES_41) {
    return 41;
  }
  return 45;
}

export function getAnnualTaxesSavingPer(
  activity: ProfessionalActivity | undefined,
  numChildren: number,
  monthlyIncome: number,
  monthlyPayment: number,
) {
  const numParts = getNbPart(FamilySituation.MARIE_PACSE, numChildren);
  const tmi = getTmi(monthlyIncome, numParts);
  return Math.round((Math.min(getTaxExemptionCeiling(activity, monthlyIncome), monthlyPayment) * 12 * tmi) / 100);
}

export function setUtmLocalStorage() {
  const params = new URLSearchParams(window.location.search);
  const utm_params: string[] = [];
  params.forEach((value, key) => {
    utm_params.push(key + '=' + value);
  });
  let utm_search: string = utm_params.join('&') || '';
  const utm_bypass: string | null = localStorage.getItem('utm_bypass');
  if (utm_bypass && utm_bypass != utm_search) {
    utm_search = (utm_search && utm_search != '' ? '&' : '') + utm_bypass;
  }

  if (utm_search) {
    localStorage.setItem('utm_bypass', utm_search);
  }
}

export function calculateValueGAds(income: number, professionalActivity: ProfessionalActivity, age: number) {
  if (
    income < 2000 ||
    professionalActivity === ProfessionalActivity.CHOMEUR ||
    professionalActivity === ProfessionalActivity.ETUDIANT ||
    professionalActivity === ProfessionalActivity.RETRAITE ||
    age >= 60
  )
    return 0;

  let result = 0;
  if (income <= 3800) {
    result = 85 * Math.pow((income - 1300) / 3700, 14 / 10);
  } else if (income <= 5000) {
    result = 85 / (1 + Math.exp(-0.003 * (income - 3700)));
  } else {
    result = 85;
  }
  if (professionalActivity === ProfessionalActivity.INDEPENDANT) return result + 15;
  if (professionalActivity === ProfessionalActivity.SALARIE) return result + 12;
  return result + 6;
}
