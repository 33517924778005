import { Button, Input, ReturnButton } from '@leadsquare/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameSimulator, SubtitleQuestion, TitleQuestion } from '../../../components';
import { setFamilyAge, setIsSliderNextButtonDisabled } from '../../../data/slices';
import { RootState } from '../../../data/store';
import { PagesName } from '../../../data/types';
import { useRedirectPreviousPage, useSendData } from '../../../hooks';
import { useRedirectIfNeeded } from '../../../hooks/useRedirectIfNeeded';
import { keepDigits } from '../../../utils';

export const PageFamilleAge = () => {
  const actualPage = PagesName.FAMILLE_AGE;
  const valueFamilyAge = useSelector((state: RootState) => state.userData.family.age);
  const dispatch = useDispatch();
  const sendData = useSendData();
  const redirectIfNeeded = useRedirectIfNeeded();
  const redirectPreviousPage = useRedirectPreviousPage();
  const [inputState, setInputState] = useState<'default' | 'success' | 'error'>('default');

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    if (inputState !== 'success') dispatch(setIsSliderNextButtonDisabled(true));
    else dispatch(setIsSliderNextButtonDisabled(false));
  }, [inputState]);

  useEffect(() => {
    handleInputState((valueFamilyAge ?? 0).toString());
  }, []);

  const handleInputState = (value: string) => {
    const valueNumber = Number(value);
    if (value.length === 0 || value === '0') setInputState('default');
    else if (valueNumber >= 18 && valueNumber <= 65) setInputState('success');
    else setInputState('error');
  };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = keepDigits(event.target.value);
    const valueNumber = Number(value);
    handleInputState(value);
    dispatch(setFamilyAge(valueNumber));
  };

  return (
    <div className="page page__famille page__famille__age">
      <NameSimulator />
      <ReturnButton className="desktop" onClick={redirectPreviousPage}>
        Retour
      </ReturnButton>
      <TitleQuestion>Quel âge avez-vous ?</TitleQuestion>
      <SubtitleQuestion>Pour identifier les meilleurs dispositifs</SubtitleQuestion>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendData();
        }}
        className="page__form-slider"
      >
        <Input
          state={inputState}
          value={valueFamilyAge === 0 ? '' : valueFamilyAge ?? ''}
          onChange={handleChanges}
          inputMode="numeric"
          type="number"
          errorMessage="Veuillez entrer un âge entre 18 et 65 ans"
          placeholder="Exemple: 38"
        />
        <hr className="separator-submit" />
        <Button color="black" isArrow={true} disabled={inputState !== 'success'} className="form-slider-submit">
          Suivant
        </Button>
      </form>
    </div>
  );
};
