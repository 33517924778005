import { useDispatch } from 'react-redux';
import { SIMULATION_ACRONYM } from '../config';
import { setUuidSimulation } from '../data/slices';
import { UuidError } from './errors';
import { useStartNewSimulation } from './useStartNewSimulation';

export const uuidFromLocalStorage = () => {
  return localStorage.getItem(`uuid_simulation_${SIMULATION_ACRONYM}`);
};

export const useCheckUuids = () => {
  const startNewSimulation = useStartNewSimulation();
  const dispatch = useDispatch();
  const checkUuids = async (uuidSimulationReducer: string | undefined) => {
    if (!uuidSimulationReducer) {
      let uuidSimulation = uuidFromLocalStorage();
      if (!uuidSimulation) {
        uuidSimulation = await startNewSimulation(true);
      } else {
        await startNewSimulation(false, uuidSimulation);
      }

      if (!uuidSimulation) {
        throw new UuidError();
      }
      localStorage.setItem(`uuid_simulation_${SIMULATION_ACRONYM}`, uuidSimulation);
      dispatch(setUuidSimulation(uuidSimulation));
      return uuidSimulation;
    }
    return uuidSimulationReducer;
  };

  return checkUuids;
};
