import { Button, logicInvestLogo } from '@leadsquare/design-system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PageError404.scss';

export const PageError404 = () => {
  const navigate = useNavigate();

  return (
    <div className="page page__error__404">
      <header>{logicInvestLogo}</header>
      <main>
        <div>
          <h1>404 !</h1>
          <p>La page n&apos;existe pas.</p>
          <Button
            color="black"
            onClick={() => {
              navigate('/slider/famille/situation');
            }}
            isArrow={true}
          >
            Retourner au simulateur
          </Button>
        </div>
      </main>
    </div>
  );
};
