import {
  Accordion,
  AdvantagesDisadvantagesBlock,
  HeaderResultPage,
  InfoText,
  NotificationBlock,
  NumberBlockResult,
  Section,
  StepperDemarches,
} from '@leadsquare/design-system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Chart, PhoneModal } from '../components';
import { RETIREMENT_AGE } from '../config';
import { setMonthlyPayment } from '../data/slices';
import { RootState } from '../data/store';
import { PagesName } from '../data/types';
import { useRedirectIfNeeded } from '../hooks/useRedirectIfNeeded';
import {
  accountingCoinsStackIcon,
  checkBadgeIcon,
  currencyEurosIcon,
  likePlusBubbleIcon,
  messagesBubbleSquareInformationIcon,
  moneyWalletIcon,
} from '../static/icons';
import { getAnnualTaxesSavingPer, getCapitalPerOnly, getTaxExemptionCeiling, keepDigits, putSpaces } from '../utils';
import './PageResultats.scss';

export const PageResultats = () => {
  const actualPage = PagesName.RESULTATS;
  const redirectIfNeeded = useRedirectIfNeeded();
  const dispatch = useDispatch();
  const firstName = useSelector((state: RootState) => state.userData.information.firstName);
  const lastName = useSelector((state: RootState) => state.userData.information.lastName);
  const monthlyPayment = useSelector((state: RootState) => state.internalData.frontend.monthlyPayment);
  const age = useSelector((state: RootState) => state.userData.family.age);
  const professionalActivity = useSelector((state: RootState) => state.userData.activity.professionalActivity);
  const monthlyIncome = useSelector((state: RootState) => state.userData.finances.income);
  const numChildren = useSelector((state: RootState) => state.userData.family.numChildren);
  const [taxExemptionCeiling, setTaxExemptionCeiling] = React.useState(343);
  const [capitalTerm, setCapitalTerm] = React.useState(0);
  const [annualTaxesSavingPer, setAnnualTaxesSavingPer] = React.useState(0);
  const [totalTaxesSaving, setTotalTaxesSaving] = React.useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    const taxExemptionCeiling = getTaxExemptionCeiling(professionalActivity, monthlyIncome);
    setTaxExemptionCeiling(taxExemptionCeiling);
    dispatch(setMonthlyPayment(taxExemptionCeiling));
  }, [monthlyIncome, professionalActivity]);

  useEffect(() => {
    setCapitalTerm(getCapitalPerOnly(monthlyPayment, age ?? 18));
  }, [monthlyPayment, age]);

  useEffect(() => {
    setAnnualTaxesSavingPer(
      getAnnualTaxesSavingPer(professionalActivity, numChildren ?? 0, monthlyIncome ?? 0, monthlyPayment),
    );
  }, [monthlyPayment, monthlyIncome, professionalActivity, numChildren]);

  useEffect(() => {
    setTotalTaxesSaving(annualTaxesSavingPer * (RETIREMENT_AGE - (age ?? 18)));
  }, [annualTaxesSavingPer, age]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = keepDigits(event.target.value);
    const valueNumber = Number(value);
    if (valueNumber > taxExemptionCeiling * 3) return;
    dispatch(setMonthlyPayment(valueNumber));
  };

  const onSliderChange = (value: number[]) => {
    dispatch(setMonthlyPayment(value[0]));
  };

  return (
    <div className="page page-resultats">
      <PhoneModal />
      <HeaderResultPage
        onClick={() => {
          navigate('/account');
        }}
      />

      <div className="page-resultats__content">
        <NotificationBlock
          className="notification-eligible"
          type="success"
          title={`Félicitations ${firstName ?? ''} ${lastName ?? ''}, vous êtes éligible au plan épargne retraite !`}
        >
          Notre algorithme a calculé votre plan épargne retraite, voici vos résultats !
        </NotificationBlock>

        <Section color="purple">
          <div className="section__title-container">
            {accountingCoinsStackIcon}
            <h3>Simulation de votre capital disponible à la retraite</h3>
          </div>
          <p>
            Le PER est un produit d’épargne qui vous permet de placer et faire fructifier votre épargne afin de préparer
            votre retraite. Les versements sont libres et peuvent avoir un impact immédiat sur votre taux de prélèvement
            à la source.
          </p>
          <div className="section__number-block-container">
            <NumberBlockResult
              color="white"
              title="Estimation de votre capital retraite"
              value={putSpaces(capitalTerm, 3)}
            />
            <NumberBlockResult
              color="purple"
              title="Économie d’impôts potentielle"
              value={putSpaces(totalTaxesSaving, 3)}
            />
          </div>
          <Chart
            monthlyPayment={monthlyPayment}
            age={age ?? 18}
            onInputChange={onInputChange}
            onSliderChange={onSliderChange}
            taxExemptionCeiling={taxExemptionCeiling}
          />
          <InfoText>
            Vos données sont partagées uniquement avec des partenaires fiables, régulés et agrées par les autorités
            financières.
          </InfoText>
        </Section>

        <Section color="green">
          <div className="section__title-container">
            {currencyEurosIcon}
            <h3>Combien d’impôts puis-je économiser ?</h3>
          </div>
          <p>
            Vous pouvez en partie déduire vos versements de votre revenu imposable, avec pour conséquence directe une
            baisse de votre impôt sur le revenu. Si vous ne le faites pas, vous ne serez pas imposé sur les plus values
            à la sortie.
          </p>
          <div className="section__number-block-container">
            <NumberBlockResult
              color="white"
              title="Vos versements en 2022"
              value={putSpaces(monthlyPayment * 12, 3) + ',00'}
            />
            <NumberBlockResult
              color="green"
              title="Impôts économisés en 2022"
              value={putSpaces(annualTaxesSavingPer, 3) + ',00'}
            />
          </div>
        </Section>

        <Section color="blue" className="section-advantages-disadvantages">
          <div className="section__title-container">
            {likePlusBubbleIcon}
            <h3>Avantages et Inconvénients</h3>
          </div>
          <AdvantagesDisadvantagesBlock
            advantagesDisadvantages={[
              { type: 'advantage', content: "Réduction d'impôt possible chaque année jusqu'a sa retraite" },
              { type: 'advantage', content: "Diversité des supports d'épargne (à l'image de l'assurance vie)" },
              { type: 'advantage', content: "Création d'un complément de revenus pour sa retraite" },
            ]}
          />
          <AdvantagesDisadvantagesBlock
            advantagesDisadvantages={[
              { type: 'disadvantage', content: 'Risque de perte en capital sur les unités de compte' },
              {
                type: 'disadvantage',
                content: "Indisponibilité de fonds jusqu'à la retraite (sauf cas de déblocage anticipé)",
              },
              { type: 'disadvantage', content: 'Fiscalité sur les rentes ou le capital à la sortie' },
            ]}
          />
        </Section>

        <Section color="yellow" className="section-when-available">
          <div className="section__title-container">
            {moneyWalletIcon}
            <h3>Quand sera disponible mon argent ?</h3>
          </div>
          <Accordion className="at-retirement" title="Au moment de votre retraite">
            <div className="accordion-at-retirement-content">
              <p>
                Par défaut, votre épargne est bloquée jusqu&apos;à vote retraite. Vous pourrez choisir de retirer votre
                argent de votre plan épargne retraite selon les modalités suivantes:
              </p>
              <div className="flex">
                {checkBadgeIcon}
                <div>
                  <h5>Versement du capital</h5>
                  <p className="body2">
                    Votre épargne vous est versée en un seul virement sur le compte bancaire de votre choix.
                  </p>
                </div>
              </div>
              <div className="flex">
                {checkBadgeIcon}
                <div>
                  <h5>Rente mensuelle</h5>
                  <p className="body2">
                    Vote épargne vous est versée un petit peu tous les mois, au montant et sur la durée de vote choix.
                  </p>
                </div>
              </div>
              <div className="flex">
                {checkBadgeIcon}
                <div>
                  <h5>Combinaison des deux</h5>
                  <p className="body2">
                    Vous pouvez opter pour un premier versement de capital et vous assurer une rente mensuelle
                    supplementaire.
                  </p>
                </div>
              </div>
            </div>
          </Accordion>
          <Accordion className="before-retirement" title="Avant votre retraite (déblocage anticipé)" defaultOpen={true}>
            <div className="accordion-before-retirement-content">
              <p>
                Il suffit de nous faire parvenir le justificatif correspondant à l&apos;une des situations ci-dessous
                pour que vos fonds soient débloqués!
              </p>
              <div className="flex">
                {checkBadgeIcon}
                <div>
                  <h5>Pour l’achat d’une résidence principale</h5>
                  <p className="body2">
                    Vous pouvez débloquer votre épargne pour financer l&apos;achat de vote résidence principale (et
                    uniquement vote résidence principale).
                  </p>
                </div>
              </div>
              <div className="flex">
                {checkBadgeIcon}
                <div>
                  <h5>En cas de perte de revenues</h5>
                  <ul>
                    <li className="body2">Expiration des droits aux allocations chômage</li>
                    <li className="body2">Surendettement</li>
                    <li className="body2">
                      Cessation d&apos;activité non salariée à la suite d&apos;un jugement de liquidation judiciaire
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex">
                {checkBadgeIcon}
                <div>
                  <h5>Combinaison des deux</h5>
                  <ul>
                    <li className="body2">
                      Invalidité du titulaire du contrat, de ses enfants, de son époux ou épouse ou de son partenaire de
                      Pacs
                    </li>
                    <li className="body2">Décès de l&apos;époux ou l&apos;épouse ou du partenaire de Pacs</li>
                  </ul>
                </div>
              </div>
            </div>
          </Accordion>
        </Section>

        <Section color="purple" className="section-demarches">
          <div className="section__title-container">
            {messagesBubbleSquareInformationIcon}
            <h3>Quelles sont les démarches</h3>
          </div>
          <StepperDemarches
            activeStep={1}
            steps={[
              "Vous avez obtenu vos résultats de simulation. Vous pouvez retrouver toutes vos informations dans l'onglet profil",
              "Profitez d'un bilan partimonial gratuit. Un(e) expert(e) de notre réseau partenaire vous appellera dans 24 h pour effectuer votre bilan patrimonial gratuitement.",
              "Choisissez votre montant d'épargne. Votre conseiller(ère) vous orientera vers le montant le plus adapté en fonction de votre situation.",
              'Épargnez pour votre retraite et réduisez vos impôts dès cette année.',
            ]}
          />
        </Section>

        <NotificationBlock
          className="notification-block-risk-warning"
          type="info"
          title="Investir présente un risque de perte de capital."
        >
          Les performances passées ne préjugent pas des performances futures. LogicInvest ne garantit pas les
          performances affichées. Cette simulation a pour objectif de donner un ordre de grandeur de l’évolution d’un
          investisseur sur un PER. Ces données sont dépourvues de valeur contractuelle. Nous vous rappelons que les
          supports d’investissement ne bénéficient d’aucune garantie en capital et sont sujets à des fluctuations à la
          hausse ou à la baisse dépendant des marchés financiers.
        </NotificationBlock>
      </div>
    </div>
  );
};
