import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAGES_ORDER } from '../../config';
import { InternalDataState, PagesName } from '../types';

const initialState: InternalDataState = {
  frontend: {
    activeCategory: 1,
    activePage: PAGES_ORDER[0],
    progressBarAnimated: true,
    isLastCheckedProgressBar: false,
    isSliderNextButtonDisabled: true,
    monthlyPayment: 343,
  },
  backend: {
    uuidSimulation: undefined,
    isPhoneChecked: false,
  },
};

export const internalDataSlice = createSlice({
  name: 'internalData',
  initialState,
  reducers: {
    setUuidSimulation: (state: InternalDataState, action: PayloadAction<string>) => {
      state.backend.uuidSimulation = action.payload;
    },
    setActiveCategory: (state: InternalDataState, action: PayloadAction<number>) => {
      state.frontend.activeCategory = action.payload;
    },
    setActivePage: (state: InternalDataState, action: PayloadAction<PagesName>) => {
      state.frontend.activePage = action.payload;
    },
    setProgressBarAnimated: (state: InternalDataState, action: PayloadAction<boolean>) => {
      state.frontend.progressBarAnimated = action.payload;
    },
    setIsLastCheckedProgressBar: (state: InternalDataState, action: PayloadAction<boolean>) => {
      state.frontend.isLastCheckedProgressBar = action.payload;
    },
    setIsSliderNextButtonDisabled: (state: InternalDataState, action: PayloadAction<boolean>) => {
      state.frontend.isSliderNextButtonDisabled = action.payload;
    },
    setMonthlyPayment: (state: InternalDataState, action: PayloadAction<number>) => {
      state.frontend.monthlyPayment = action.payload;
    },
    setIsPhoneChecked: (state: InternalDataState, action: PayloadAction<boolean>) => {
      state.backend.isPhoneChecked = action.payload;
    },
    setInternalDataBackend: (state: InternalDataState, action: PayloadAction<InternalDataState['backend']>) => {
      state.backend = action.payload;
    },
  },
});

export const {
  setUuidSimulation,
  setActiveCategory,
  setActivePage,
  setProgressBarAnimated,
  setIsLastCheckedProgressBar,
  setIsSliderNextButtonDisabled,
  setMonthlyPayment,
  setIsPhoneChecked,
  setInternalDataBackend,
} = internalDataSlice.actions;
