import { Button, Input, ReturnButton, Toggle } from '@leadsquare/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameSimulator, SubtitleQuestion, TitleQuestion } from '../../../components';
import { setEmail, setIsCguAccepted, setIsSliderNextButtonDisabled } from '../../../data/slices';
import { RootState } from '../../../data/store';
import { PagesName } from '../../../data/types';
import { useRedirectPreviousPage, useSendData } from '../../../hooks';
import { useRedirectIfNeeded } from '../../../hooks/useRedirectIfNeeded';
import { checkEmail } from '../../../utils';

export const PageInformationsEmail = () => {
  const actualPage = PagesName.INFORMATIONS_EMAIL;
  const valueInformationEmail = useSelector((state: RootState) => state.userData.information.email);
  const valueIsCguAccepted = useSelector((state: RootState) => state.userData.other.isCguAccepted);
  const dispatch = useDispatch();
  const sendData = useSendData();
  const redirectIfNeeded = useRedirectIfNeeded();
  const redirectPreviousPage = useRedirectPreviousPage();
  const [inputState, setInputState] = useState<'default' | 'success' | 'error'>('default');

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    handleInputState(valueInformationEmail ?? '');
  }, []);

  useEffect(() => {
    if (inputState === 'success' && valueIsCguAccepted) dispatch(setIsSliderNextButtonDisabled(false));
    else dispatch(setIsSliderNextButtonDisabled(true));
  }, [inputState, valueIsCguAccepted]);

  const handleInputState = (value: string) => {
    if (value.length === 0) setInputState('default');
    else if (checkEmail(value)) setInputState('success');
    else setInputState('error');
  };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handleInputState(value);
    dispatch(setEmail(value));
  };

  return (
    <div className="page page__information page__information_email">
      <NameSimulator />
      <ReturnButton className="desktop" onClick={redirectPreviousPage}>
        Retour
      </ReturnButton>
      <TitleQuestion>Quel est votre email </TitleQuestion>
      <SubtitleQuestion>Nécessaire pour accéder à nouveau a vos résultats</SubtitleQuestion>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendData();
        }}
        className="page__form-slider"
      >
        <Input
          state={inputState}
          value={valueInformationEmail ?? ''}
          onChange={handleChanges}
          type="email"
          placeholder="Ex: hello@logic-invest.com"
        />
        <div className="slider__page__optin-cgu-container">
          <Toggle
            value={valueIsCguAccepted ?? false}
            onChange={() => dispatch(setIsCguAccepted(!valueIsCguAccepted))}
          />
          <p className="body2">
            Je reconnais avoir pris connaissance et accepté les&nbsp;
            <a href="https://www.logic-invest.com/conditions-generales" rel="noreferrer" target="_blank">
              conditions générales d&apos;utilisations&nbsp;
            </a>
            et la&nbsp;
            <a href="https://www.logic-invest.com/politique-de-confidentialite" rel="noreferrer" target="_blank">
              politique de confidentialité
            </a>
            .<span className="color-red">*</span>
          </p>
        </div>
        <hr className="separator-submit" />
        <Button
          color="black"
          isArrow={true}
          disabled={inputState !== 'success' || !valueIsCguAccepted}
          className="form-slider-submit"
        >
          Suivant
        </Button>
      </form>
    </div>
  );
};
