import { Button, Input, ReturnButton } from '@leadsquare/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameSimulator, SubtitleQuestion, TitleQuestion } from '../../../components';
import { setIncome, setIsSliderNextButtonDisabled } from '../../../data/slices';
import { RootState } from '../../../data/store';
import { PagesName } from '../../../data/types';
import { useRedirectPreviousPage, useSendData } from '../../../hooks';
import { useRedirectIfNeeded } from '../../../hooks/useRedirectIfNeeded';
import { keepDigits, putSpaces } from '../../../utils';

export const PageFinancesRevenus = () => {
  const actualPage = PagesName.FINANCES_REVENUS;
  const valueFinancesIncome = useSelector((state: RootState) => state.userData.finances.income);
  const dispatch = useDispatch();
  const sendData = useSendData();
  const redirectIfNeeded = useRedirectIfNeeded();
  const redirectPreviousPage = useRedirectPreviousPage();
  const [inputState, setInputState] = useState<'default' | 'success' | 'error'>('default');

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    if (inputState === 'success') dispatch(setIsSliderNextButtonDisabled(false));
    else dispatch(setIsSliderNextButtonDisabled(true));
  }, [inputState, dispatch]);

  useEffect(() => {
    handleInputState((valueFinancesIncome ?? 0).toString());
  }, []);

  const handleInputState = (value: string) => {
    const valueNumber = Number(value);
    if (value.length === 0 || value === '0') setInputState('default');
    else if (valueNumber >= 1 && valueNumber <= 1000000) setInputState('success');
    else setInputState('error');
  };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = keepDigits(event.target.value);
    const valueNumber = Number(value);
    handleInputState(value);
    dispatch(setIncome(valueNumber));
  };

  return (
    <div className="page page__famille page__famille__age">
      <NameSimulator />
      <ReturnButton className="desktop" onClick={redirectPreviousPage}>
        Retour
      </ReturnButton>
      <TitleQuestion>Quels sont vos revenus nets mensuels avant impôts?</TitleQuestion>
      <SubtitleQuestion>Pour identifier les meilleurs dispositifs</SubtitleQuestion>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendData();
        }}
        className="page__form-slider"
      >
        <Input
          state={inputState}
          value={putSpaces(valueFinancesIncome ?? 0, 3) === '0' ? '' : putSpaces(valueFinancesIncome ?? 0, 3)}
          onChange={handleChanges}
          inputMode="numeric"
          type="text"
          placeholder="Ex: 4 000€"
          errorMessage="Veuillez entrer vos revenus mensuels"
        />
        <hr className="separator-submit" />
        <Button color="black" isArrow={true} disabled={inputState !== 'success'} className="form-slider-submit">
          Suivant
        </Button>
      </form>
    </div>
  );
};
