import { ModalVerifyPhone } from '@leadsquare/design-system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPhoneChecked, setPhone } from '../../data/slices';
import { RootState } from '../../data/store';
import { useCheckPhoneVerification, useSendPhoneVerification } from '../../hooks';
import { keepDigits, putSpacePhone } from '../../utils';
import { useResendPhoneVerification } from '@/hooks/useResendPhoneVerification';

export const PhoneModal = () => {
  const [code, setCode] = React.useState<string>('');
  const [isBadPhone, setIsBadPhone] = React.useState<boolean>(false);
  const [stateInputCode, setStateInputCode] = React.useState<'selected' | 'error' | 'success'>('selected');
  const [stateInputPhone, setStateInputPhone] = React.useState<'selected' | 'error' | 'success'>('selected');
  const phone = useSelector((state: RootState) => state.userData.information.phone);
  const dispatch = useDispatch();
  const sendPhoneVerification = useSendPhoneVerification();
  const checkPhoneVerification = useCheckPhoneVerification();
  const resendPhoneVerification = useResendPhoneVerification();
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const isPhoneChecked = useSelector((state: RootState) => state.internalData.backend.isPhoneChecked);

  const handleCode = (code: string) => {
    const valueNumber = keepDigits(code);
    if (code.length === 0) setStateInputCode('selected');
    else if (valueNumber.length === 4) setStateInputCode('success');
    else setStateInputCode('selected');
    setCode(valueNumber);
  };

  const handleChangePhone = (phone: string) => {
    const valueNumber = keepDigits(phone);
    if (phone.length === 0) setStateInputPhone('selected');
    else if (valueNumber.length === 10) setStateInputPhone('success');
    else setStateInputPhone('error');
    dispatch(setPhone(valueNumber));
  };

  const handleSubmitCode = async () => {
    const isPhoneChecked = await checkPhoneVerification(code, setErrorMessage);
    if (isPhoneChecked) {
      dispatch(setIsPhoneChecked(true));
      return;
    }
    setStateInputCode('error');
  };

  const handleSubmitResend = async () => {
    const isSent = await sendPhoneVerification();
    if (isSent) {
      setIsBadPhone(false);
      return;
    }
    setStateInputPhone('error');
  };

  const handleResendVerification = () => {
    resendPhoneVerification(setErrorMessage);
    setStateInputCode('error');
  };

  if (isPhoneChecked) return null;

  return (
    <ModalVerifyPhone
      code={code}
      onChangeCode={handleCode}
      isBadPhone={isBadPhone}
      onChangePhone={handleChangePhone}
      onSubmitCode={handleSubmitCode}
      onSubmitResend={handleSubmitResend}
      phone={putSpacePhone(phone ?? '')}
      setIsBadPhone={setIsBadPhone}
      stateInputCode={stateInputCode}
      stateInputPhone={stateInputPhone}
      errorMessage={errorMessage}
      resendCode={handleResendVerification}
    />
  );
};
