import { Button, frenchFlagIcon, Input, ReturnButton } from '@leadsquare/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NameSimulator, SubtitleQuestion, TitleQuestion } from '../../../components';
import { setIsSliderNextButtonDisabled, setPhone } from '../../../data/slices';
import { RootState } from '../../../data/store';
import { PagesName } from '../../../data/types';
import { useRedirectPreviousPage, useSendData } from '../../../hooks';
import { useRedirectIfNeeded } from '../../../hooks/useRedirectIfNeeded';
import { keepDigits, putSpacePhone } from '../../../utils';

export const PageInformationsTelephone = () => {
  const actualPage = PagesName.INFORMATIONS_TELEPHONE;
  const valueInformationPhone = useSelector((state: RootState) => state.userData.information.phone);
  const dispatch = useDispatch();
  const sendData = useSendData();
  const redirectIfNeeded = useRedirectIfNeeded();
  const redirectPreviousPage = useRedirectPreviousPage();
  const [inputState, setInputState] = useState<'default' | 'success' | 'error'>('default');

  useEffect(() => {
    redirectIfNeeded(actualPage);
  }, [redirectIfNeeded, actualPage]);

  useEffect(() => {
    if (inputState === 'success') dispatch(setIsSliderNextButtonDisabled(false));
    else dispatch(setIsSliderNextButtonDisabled(true));
  }, [inputState, dispatch]);

  useEffect(() => {
    handleInputState(valueInformationPhone ?? '');
  }, []);

  const handleInputState = (value: string) => {
    if (value.length === 0) setInputState('default');
    else if (value.length === 10) setInputState('success');
    else setInputState('error');
  };

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueNumber = keepDigits(value);
    handleInputState(valueNumber);
    dispatch(setPhone(valueNumber));
  };

  return (
    <div className="page page__information page__information_phone">
      <NameSimulator />
      <ReturnButton className="desktop" onClick={redirectPreviousPage}>
        Retour
      </ReturnButton>
      <TitleQuestion>Quel est votre numéro de téléphone ?</TitleQuestion>
      <SubtitleQuestion>Nécessaire pour accéder a votre résultat.</SubtitleQuestion>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendData();
        }}
        className="page__form-slider"
      >
        <Input
          leftIcon={frenchFlagIcon}
          state={inputState}
          value={putSpacePhone(valueInformationPhone ?? '')}
          onChange={handleChanges}
          type="tel"
          placeholder="Ex: 06 01 02 03 04"
        />
        <hr className="separator-submit" />
        <Button color="black" isArrow={true} disabled={inputState !== 'success'} className="form-slider-submit">
          Suivant
        </Button>
      </form>
    </div>
  );
};
