import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDataState, FamilySituation, ProfessionalActivity } from '../types';

const initialState: UserDataState = {
  family: {
    situation: undefined,
    age: undefined,
    numChildren: undefined,
  },
  activity: {
    professionalActivity: undefined,
  },
  finances: {
    income: undefined,
  },
  information: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
  },
  other: {
    isCguAccepted: undefined,
  },
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setFamilySituation: (state: UserDataState, action: PayloadAction<FamilySituation | undefined>) => {
      state.family.situation = action.payload;
    },
    setFamilyAge: (state: UserDataState, action: PayloadAction<number>) => {
      state.family.age = action.payload;
    },
    setFamilyNumChildren: (state: UserDataState, action: PayloadAction<number>) => {
      state.family.numChildren = action.payload;
    },
    setProfessionalActivity: (state: UserDataState, action: PayloadAction<ProfessionalActivity>) => {
      state.activity.professionalActivity = action.payload;
    },
    setIncome: (state: UserDataState, action: PayloadAction<number>) => {
      state.finances.income = action.payload;
    },
    setFirstName: (state: UserDataState, action: PayloadAction<string>) => {
      state.information.firstName = action.payload;
    },
    setLastName: (state: UserDataState, action: PayloadAction<string>) => {
      state.information.lastName = action.payload;
    },
    setEmail: (state: UserDataState, action: PayloadAction<string>) => {
      state.information.email = action.payload;
    },
    setPhone: (state: UserDataState, action: PayloadAction<string>) => {
      state.information.phone = action.payload;
    },
    setIsCguAccepted: (state: UserDataState, action: PayloadAction<boolean>) => {
      state.other.isCguAccepted = action.payload;
    },
    setUserData: (state: UserDataState, action: PayloadAction<UserDataState>) => {
      state = action.payload;
      return state;
    },
  },
});

export const {
  setEmail,
  setFamilyAge,
  setFamilyNumChildren,
  setFamilySituation,
  setFirstName,
  setIncome,
  setIsCguAccepted,
  setLastName,
  setPhone,
  setProfessionalActivity,
  setUserData,
} = userDataSlice.actions;
