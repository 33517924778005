import { FamilySituation, ProfessionalActivity } from './enums';

export const mappingProfessionalActivity = [
  { value: ProfessionalActivity.SALARIE, label: 'Salarié(e)' },
  { value: ProfessionalActivity.INDEPENDANT, label: 'Indépendant(e) / Dirigeant(e)' },
  { value: ProfessionalActivity.ETUDIANT, label: 'Etudiant(e) / Apprenti(e)' },
  { value: ProfessionalActivity.CHOMEUR, label: 'Au chômage / Sans activité' },
  { value: ProfessionalActivity.RETRAITE, label: 'Retraité(e)' },
];

export const mappingFamilySituation = [
  { value: FamilySituation.CELIBATAIRE, label: 'Célibataire' },
  { value: FamilySituation.MARIE_PACSE, label: 'Marié(e) / Pacsé(e)' },
  { value: FamilySituation.DIVORCE, label: 'Divorcé(e)' },
  { value: FamilySituation.CONCUBINAGE, label: 'En concubinage' },
  { value: FamilySituation.VEUF, label: 'Veuf(ve)' },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findLabelFromMapping = (mapping: any, value: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return mapping.find((item: any) => item.value === value).label;
};
