export enum FamilySituation {
  CELIBATAIRE = 'CELIBATAIRE',
  MARIE_PACSE = 'MARIE_PACSE',
  DIVORCE = 'DIVORCE',
  VEUF = 'VEUF',
  CONCUBINAGE = 'CONCUBINAGE',
}

export enum ProfessionalActivity {
  SALARIE = 'SALARIE',
  INDEPENDANT = 'INDEPENDANT',
  RETRAITE = 'RETRAITE',
  ETUDIANT = 'ETUDIANT',
  CHOMEUR = 'CHOMEUR',
}

export enum PagesName {
  ACTIVITE_PROFESSION = 'SLIDER_ACTIVITE_PROFESSION',
  FAMILLE_AGE = 'SLIDER_FAMILLE_AGE',
  FAMILLE_ENFANTS = 'SLIDER_FAMILLE_ENFANTS',
  FAMILLE_SITUATION = 'SLIDER_FAMILLE_SITUATION',
  FINANCES_REVENUS = 'SLIDER_FINANCES_REVENUS',
  INFORMATIONS_EMAIL = 'SLIDER_INFORMATIONS_EMAIL',
  INFORMATIONS_NOM = 'SLIDER_INFORMATIONS_NOM',
  INFORMATIONS_TELEPHONE = 'SLIDER_INFORMATIONS_TELEPHONE',
  CALCULS = 'CALCULS',
  RESULTATS = 'RESULTATS',
}
