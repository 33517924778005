import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PAGES_ORDER_PER_CATEGORY_SLIDER } from '../config';
import { setActiveCategory, setProgressBarAnimated } from '../data/slices';
import { RootState } from '../data/store';
import { PagesName } from '../data/types';

const useSetInformationForProgressBar = () => {
  const dispatch = useDispatch();

  const setInformationForProgressBar = (activePageReducer: PagesName) => {
    for (let i = 0; i < PAGES_ORDER_PER_CATEGORY_SLIDER.length; i++) {
      const CATEGORY = PAGES_ORDER_PER_CATEGORY_SLIDER[i];
      const indexInCategory = CATEGORY.indexOf(activePageReducer);
      if (indexInCategory !== -1) {
        if (indexInCategory === 0) {
          dispatch(setProgressBarAnimated(true));
          dispatch(setActiveCategory(i + 1));
        } else {
          dispatch(setProgressBarAnimated(false));
          dispatch(setActiveCategory(i + 1));
        }
      }
    }
  };

  return setInformationForProgressBar;
};

export const useRedirectIfNeeded = () => {
  const navigate = useNavigate();
  const activePageReducer = useSelector((state: RootState) => state.internalData.frontend.activePage);
  const setInformationForProgressBar = useSetInformationForProgressBar();

  const redirectIfNeeded = (actualPage: PagesName) => {
    if (activePageReducer === undefined) return;
    if (activePageReducer !== actualPage) {
      let pageToRedirect = activePageReducer as string;
      pageToRedirect = pageToRedirect.toLowerCase();
      pageToRedirect = pageToRedirect.replace(/_/g, '/');
      setInformationForProgressBar(activePageReducer);
      navigate(`/${pageToRedirect}`);
    }
  };

  return redirectIfNeeded;
};
