import { useDispatch, useSelector } from 'react-redux';
import { PAGES_ORDER } from '../config';
import { setActivePage } from '../data/slices';
import { RootState } from '../data/store';

export const useRedirectPreviousPage = () => {
  const dispatch = useDispatch();
  const activePage = useSelector((state: RootState) => state.internalData.frontend.activePage);

  return () => {
    const newPage = PAGES_ORDER[PAGES_ORDER.indexOf(activePage ?? PAGES_ORDER[1]) - 1];
    dispatch(setActivePage(newPage));
  };
};
